@use "./../abstract/sass-vars" as vars;
//tournament admin

/* match create card*/

.team-select-container {
  width: 7.125rem;
}

.team-add-svg {
  width: var(--size-6);
  height: var(--size-6);
  border-radius: var(--radius-rounded);
  border: solid;
  border-width: var(--size-px);
  border-color: rgba(var(--border-200));
  color: rgba(var(--border-200));
  &-big {
    width: var(--size-8);
    height: var(--size-8);
    border-radius: var(--radius-rounded);
    border: solid;
    border-width: var(--size-px);
    border-color: rgba(var(--border-200));
    color: rgba(var(--border-200));
  }
  &-small {
    width: var(--size-4);
    height: var(--size-4);
    border-radius: var(--radius-rounded);
    border: solid;
    border-width: var(--size-px);
    border-color: rgba(var(--border-200));
    color: rgba(var(--border-200));
  }
}
// group component
.group-stage-container {
  overflow-x: scroll;
}
.stage-container {
  position: relative;
  width: 22rem;
  .timeline-bar {
    width: 21.5rem;
    height: var(--size-0p5);
    background-color: var(--timeline-bg-color);
    position: absolute;
    top: 63px;
    left: 222.5px;
    z-index: 2;
  }
}

.stage-header {
  width: var(--size-4);
  height: var(--size-4);
  border-radius: var(--radius-rounded);
  background-color: var(--timeline-bg-color);
}

.add-stage-container {
  position: relative;
  .timeline-bar {
    width: 21.5rem;
    height: var(--size-0p5);
    background-color: rgba(var(--border-200));
    position: absolute;
    top: 63px;
    right: 225px;
    z-index: 3;
  }
}

//Associated team
.associated-team {
  @media (max-width: vars.$breakpoint-sm) {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}

//tournament profile
.tournament-info {
  @media (max-width: vars.$breakpoint-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
