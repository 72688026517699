$modalSizeOptions: xs, sm, md, lg, xl, 2xl;
$colorOptions: primary, accent, info, success, warning, danger;

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;

  @each $size in $modalSizeOptions {
    &.size-#{$size} {
      width: var(--modal-#{$size});
      min-width: var(--modal-#{$size});
    }
  }
  ::-webkit-scrollbar {
    width: var(--size-1);
  }
  ::-webkit-scrollbar-track {
    background: var(--surface-200);
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(var(--border-200));
    border-radius: var(--radius-rounded);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--border-300));
  }
}

.title-icon {
  border-radius: var(--radius-xs);
  @each $color in $colorOptions {
    &.color-#{$color} {
      color: var(--button-fg-light-#{$color});
      background-color: var(--button-bg-light-#{$color});
    }
  }
}
