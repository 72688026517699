$avatarSizeOptions: xs, sm, md, lg, xl, 2xl, 3xl, 4xl, 5xl, 6xl;

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: var(--weight-semibold);
  background-color: rgba(var(--surface-100));
  border: 1px solid var(--transparent);
  height: 22rem;
  width: 16.5rem;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  video {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
