@use "./../abstract/sass-vars" as vars;
.news-card-min {
  min-height: var(--size-14);
}
.match-time-width {
  min-width: var(--space-6);
}

.stat-width {
  min-width: var(--space-6);
}

.tab-big {
  height: var(--size-5);
}

.stat-title-width {
  min-width: var(--size-14);
  @media (max-width: vars.$breakpoint-sm) {
    min-width: unset;
  }
}

.match-timeline-container {
  position: relative;
  width: 100%;
  height: var(--size-12);

  .match-timeline {
    width: 100%;
    height: var(--size-0p5);
    background-color: var(--surface-100);
    border-radius: var(--border-radius-sm);
  }
  .match-timeline-event-top,
  .match-timeline-event-bottom {
    position: absolute;
    transform: translateX(-50%);
  }
  .match-timeline-event-top {
    bottom: 100%;
  }
  .match-timeline-event-bottom {
    top: 100%;
  }
}

.match-header {
  flex: 1 0 0;
}

.clickable {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

//match-form admin
.formation-container {
  position: relative;
  .lineup-grid {
    position: absolute;
    top: 1%;
    left: 0;
    right: 0;
    bottom: 12%;
    grid-template-columns: repeat(15, 1fr);
    grid-template-rows: repeat(11, 1fr);
    .lineup-grid-cell {
      position: relative;
      .lineup-grid-cell-content {
        width: var(--size-12);
        height: var(--size-12);
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .bench-grid {
    position: absolute;
    left: 0;
    right: 0;
    top: 85%;
    bottom: 5%;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(1, 1fr);
    .bench-grid-cell {
      position: relative;
      .bench-grid-cell-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .player-cell-container {
    width: var(--size-12);
    height: var(--size-12);
    cursor: default;
    position: relative;
    .remove-button {
      position: absolute;
      left: 50%;
      bottom: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
    }
  }
}

.player-grid {
  .player-add-display {
    cursor: pointer;
    visibility: hidden;
  }
  &:hover .player-add-display {
    visibility: visible;
  }
}

//penalty
.penalty-icon {
  @media (max-width: vars.$breakpoint-sm) {
    width: var(--size-md-mobile);
  }
}
.penalty-height {
  min-height: 32px;
  max-height: 32px;
}

//match-header
.match-header {
  .match-team-title {
    &.short-name {
      display: none;
    }
  }
  @media (max-width: vars.$breakpoint-xs) {
    .match-team-title {
      font-size: var(--title-h4-mobile);
    }
    .match-team-container {
      gap: var(--size-1) !important;
    }
    .match-team-avatar,
    &.avatar &.size-2xl {
      min-width: var(--avatar-xl-mobile) !important;
      width: var(--avatar-xl-mobile) !important;
      height: var(--avatar-xl-mobile) !important;
    }
  }
  @media (max-width: vars.$breakpoint-sm) {
    .match-team-title {
      &.short-name {
        display: block;
      }
      &.full-name {
        display: none;
      }
    }
  }
}

//match-lineup
.match-lineup {
  .match-lineup-horizontal {
    display: block;
  }
  .match-lineup-vertical {
    display: none;
  }
  @media (max-width: vars.$breakpoint-sm) {
    .match-lineup-horizontal {
      display: none;
    }
    .match-lineup-vertical {
      display: block;
    }
  }
  .match-lineup-grid {
    @media (max-width: vars.$breakpoint-xs) {
      padding: var(--space-2) !important;
      .jersey {
        width: 30px !important;
        height: 30px !important;
        .jersey-number {
          font-size: 12px !important;
        }
        .jersey-name {
          font-size: 9px !important;
          line-height: 0.8;
        }
        .result-badge {
          width: 10px !important;
          height: 10px !important;
          svg {
            width: 10px !important;
            height: 10px !important;
          }
        }
      }
    }

    @media (min-width: vars.$breakpoint-xs) and (max-width: vars.$breakpoint-md) {
      padding: var(--space-4) !important;
      .jersey {
        width: 40px !important;
        height: 40px !important;
        .jersey-number {
          font-size: 16px !important;
        }
        .jersey-name {
          font-size: 10px !important;
          line-height: 0.8;
        }
        .result-badge {
          width: 15px !important;
          height: 15px !important;
          svg {
            width: 15px !important;
            height: 15px !important;
          }
        }
      }
    }

    @media (min-width: vars.$breakpoint-md) and (max-width: vars.$breakpoint-lg) {
      padding: var(--space-4) !important;
      .jersey {
        width: 45px !important;
        height: 45px !important;
        .jersey-number {
          font-size: 18px !important;
        }
        .jersey-name {
          font-size: 12px !important;
          line-height: 0.8;
        }
        .result-badge {
          width: 20px !important;
          height: 20px !important;
          svg {
            width: 20px !important;
            height: 20px !important;
          }
        }
      }
    }

    @media (min-width: vars.$breakpoint-lg) and (max-width: vars.$breakpoint-xl) {
      padding: var(--space-4) !important;
      .jersey {
        width: 45px !important;
        height: 45px !important;
        .jersey-number {
          font-size: 18px !important;
        }
        .jersey-name {
          font-size: 12px !important;
          line-height: 0.8;
        }
        .result-badge {
          width: 20px !important;
          height: 20px !important;
          svg {
            width: 20px !important;
            height: 20px !important;
          }
        }
      }
    }
  }
}

//brackets
.bracket-card {
  min-width: 20rem;
  max-width: 20rem;
  @media (max-width: vars.$breakpoint-md) {
    min-width: 13rem;
    max-width: 13rem;
  }
}
