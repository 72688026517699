$loaderSizeOptions: (
  xs: var(--size-1),
  sm: var(--size-2),
  md: var(--size-4),
  lg: var(--size-8),
);

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 0%, 0.1);
  z-index: 1000;

  &.within-content {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes l5 {
  0% {
    box-shadow: 20px 0 rgba(var(--gray-200)), -20px 0 rgba(var(--gray-300));
    background: rgba(var(--gray-200));
  }
  33% {
    box-shadow: 20px 0 rgba(var(--gray-200)), -20px 0 rgba(var(--gray-300));
    background: rgba(var(--gray-300));
  }
  66% {
    box-shadow: 20px 0 rgba(var(--gray-300)), -20px 0 rgba(var(--gray-200));
    background: rgba(var(--gray-300));
  }
  100% {
    box-shadow: 20px 0 rgba(var(--gray-300)), -20px 0 rgba(var(--gray-200));
    background: rgba(var(--gray-200));
  }
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.within-content {
    position: absolute !important;
  }

  &.loader-dots {
    width: map-get($loaderSizeOptions, sm);
    aspect-ratio: 1;
    border-radius: 50%;
    animation: l5 1s infinite linear alternate;
  }

  &.loader-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid rgba(var(--gray-300));
    border-left-color: rgba(var(--white));
    border-radius: 50%;
    width: map-get($loaderSizeOptions, sm);
    aspect-ratio: 1;
    animation: spin 1s linear infinite;
  }

  &.size-xs {
    width: map-get($loaderSizeOptions, xs);
  }

  &.size-sm {
    width: map-get($loaderSizeOptions, sm);
  }

  &.size-md {
    width: map-get($loaderSizeOptions, md);
  }

  &.size-lg {
    width: map-get($loaderSizeOptions, lg);
  }
}
