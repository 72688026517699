@use "./../abstract/sass-vars" as vars;

.app-content-wrapper {
  margin-left: var(--sidenav-width-collapsed);

  @media (max-width: vars.$breakpoint-md) {
    margin-left: 0;
    // margin-top: var(--sidenav-width-collapsed);
  }

  @media (min-width: vars.$breakpoint-lg) {
    margin-left: var(--sidenav-width-collapsed);
  }
}
