@use "./../abstract/sass-vars" as vars;
$colorOptions: info, success, danger, dark, gray;
.result-badge {
  width: var(--size-lg);
  height: var(--size-lg);
  line-height: var(--size-lg);
  border-radius: var(--radius-sm);
  color: rgba(var(--white));

  @media (max-width: vars.$breakpoint-md) {
    width: var(--size-md);
    height: var(--size-md);
  }

  @each $color in $colorOptions {
    &.color-#{$color} {
      background-color: rgb(var(--bg-solid-#{$color}));
    }
  }
}
