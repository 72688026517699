@use "./../abstract/sass-vars" as vars;
.pill {
  height: var(--pill-height);
  padding: 0 var(--pill-padding);
  background-color: rgb(var(--bg-solid-accent-dark));
  color: rgba(var(--white));
  position: relative;
  border-radius: var(--radius-rounded);
  line-height: 1;

  .result-indicator {
    background-color: rgba(var(--white));
    color: rgba(var(--text-dark));
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: var(--shadow-sm);

    .svg-icon {
      width: var(--text-sm);
      height: var(--text-sm);
    }

    &.arrow-left {
      left: calc(var(--space-2xs) * -1);
      color: rgba(var(--black));
    }

    &.arrow-right {
      right: calc(var(--space-2xs) * -1);
      color: rgba(var(--black));
    }
  }
}

.pill-live {
  background-color: rgba(var(--bg-translucent-success));
  color: rgba(var(--text-success));
  padding: 0 var(--space-2xs);
  height: var(--size-md);
  border-radius: var(--radius-rounded);
  line-height: 1;

  .svg-icon {
    height: var(--size-sm);
    width: var(--size-sm);
  }

  @media (max-width: vars.$breakpoint-xs) {
    height: var(--size-md-mobile) !important;
    .pill-label {
      font-size: 9px !important;
    }
  }
}

.big-pill {
  height: var(--big-pill-height) !important;
  @media (max-width: vars.$breakpoint-xs) {
    height: var(--pill-height) !important;
  }
}

.small-pill {
  height: var(--small-pill-height) !important;
}
