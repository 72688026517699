@use "../abstract/sass-vars" as var;
$breakpoints: sm, md, lg, xl, 2xl;
$maxCounter: 0;

@mixin responsive-column-width($width, $breakpoint: default) {
  @if ($breakpoint == sm) {
    @media (min-width: var.$breakpoint-sm) {
      grid-column: span $width;
    }
  } @else if ($breakpoint == md) {
    @media (min-width: var.$breakpoint-md) {
      grid-column: span $width;
    }
  } @else if ($breakpoint == lg) {
    @media (min-width: var.$breakpoint-lg) {
      grid-column: span $width;
    }
  } @else if ($breakpoint == xl) {
    @media (min-width: var.$breakpoint-xl) {
      grid-column: span $width;
    }
  } @else if ($breakpoint == 2xl) {
    @media (min-width: var.$breakpoint-2xl) {
      grid-column: span $width;
    }
  }
}

@mixin responsive-col-hidden($breakpoint: default) {
  @if ($breakpoint == sm) {
    @media (min-width: var.$breakpoint-sm) {
      display: none;
    }
  } @else if ($breakpoint == md) {
    @media (min-width: var.$breakpoint-md) {
      display: none;
    }
  } @else if ($breakpoint == lg) {
    @media (min-width: var.$breakpoint-lg) {
      display: none;
    }
  } @else if ($breakpoint == xl) {
    @media (min-width: var.$breakpoint-xl) {
      display: none;
    }
  } @else if ($breakpoint == 2xl) {
    @media (min-width: var.$breakpoint-2xl) {
      display: none;
    }
  }
}

.grid-row {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  margin: 0 calc(1rem * -1);
  grid-row-gap: 1rem;

  > [class^="col-"],
  > * {
    padding: 0 1rem;
    grid-column: span 12;
    display: block;
  }

  .col-none {
    display: none;
  }

  @for $i from 1 through 12 {
    .col-#{$i} {
      grid-column: span $i;
    }
  }

  @each $point in $breakpoints {
    @for $i from 1 through 12 {
      .col-#{$point}-#{$i} {
        @include responsive-column-width($i, $point);
      }
    }
  }

  @each $point in $breakpoints {
    .col-#{$point}-none {
      @include responsive-col-hidden($point);
    }
  }
}
