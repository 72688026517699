@use "./../abstract/sass-vars" as vars;

.ee-side-nav {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  max-height: 100vh;
  z-index: 101;
  padding: var(--space-2);
  color: var(--text-100);
  background-color: var(--surface-100);
  box-shadow: var(--shadow-sm);
  transition: width 0.3s ease, transform 0.3s ease;
  width: var(--sidenav-width-collapsed);

  // Logo section
  .ee-side-nav-logo {
    padding: var(--size-0p5);
    svg {
      height: var(--size-5);
      width: var(--size-5);
    }
    &.ee-logo-large {
      svg {
        width: unset;
      }
    }
  }

  // Navigation items
  .ee-side-nav-item {
    display: flex;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: var(--radius-default);
    list-style: none;
  }

  // Navigation links
  .ee-side-nav-link {
    flex: 1;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: currentColor;
    height: var(--size-6);
    padding: var(--space-1);
    transition: background-color 0.3s ease, opacity 0.3s ease;

    .ee-side-nav-icon {
      display: block;
      padding: var(--space-0p5);
    }

    .ee-side-nav-label {
      display: block;
      opacity: 0;
      transition: padding 0.3s ease, opacity 0.3s ease;
      line-height: 1;
    }

    &.active {
      background-color: var(--button-bg-light-primary);
      color: var(--button-fg-light-primary);
    }

    &:hover {
      background-color: var(--button-bg-light-primary-hover);
    }
  }

  &.opened,
  &.pinned {
    width: var(--sidenav-width-expanded);
    .ee-side-nav-link {
      .ee-side-nav-label {
        opacity: 1;
        width: 100%;
        padding-left: var(--space-1);
      }
    }
  }

  // Mobile behavior
  .ee-side-nav-mobile {
    display: none !important;
  }

  @media (max-width: vars.$breakpoint-md) {
    transition: transform 0.3s ease;
    .ee-small-logo {
      display: none !important;
    }

    .ee-side-nav-mobile {
      width: 100%;
      display: flex !important;
      padding-left: var(--space-10);
    }

    &.closed {
      transform: translateX(-100%);
    }

    &.opened {
      transform: translateX(0);
      display: block;
      width: 100%;
    }
  }

  @media (min-width: vars.$breakpoint-lg) {
    width: var(--sidenav-width-collapsed);
  }
}
