@use "./../abstract/sass-vars" as vars;

.ee-toolbar {
  margin: 0 auto;
  padding: var(--space-1) 0;
  position: sticky;
  top: 0;
  width: 100%;
  max-width: var(--breakpoint-2xl);
  background-color: var(--surface-200);
  z-index: 100;
  > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .toolbar-select {
    max-width: 16rem;
    min-width: 16rem;
    @media (max-width: vars.$breakpoint-md) {
      flex: 1;
      width: 100%;
      max-width: 100%;
      min-width: var(--size-16);
    }
  }

  .toolbar-date-picker {
    min-width: 17rem;
    max-width: 17rem;
    @media (max-width: vars.$breakpoint-md) {
      flex: 1;
      max-width: 100%;
      min-width: 10.5rem;
      width: 100%;
    }
  }

  // Responsive behavior
  @media (max-width: vars.$breakpoint-md) {
    &.ee-toolbar-with-filter {
      padding-bottom: var(--space-8);
    }
    .mobile-nav-header {
      height: var(--space-8);
    }
    .user-login {
      position: fixed;
      top: 0;
      right: 0;
      padding: var(--space-xs);
    }
    .competition-filter {
      top: var(--space-14);
      left: 0;
      position: absolute;
      width: 100%;
      justify-content: space-between;
      gap: var(--space-sm-mobile);
      padding-left: var(--space-2);
      padding-right: var(--space-2);
    }
  }

  @media (max-width: vars.$breakpoint-sm) {
    .right-section {
      flex: 1;
      .search-container {
        width: 100%;
      }
    }
  }

  @media (min-width: vars.$breakpoint-sm) and (max-width: vars.$breakpoint-md) {
    .right-section {
      flex: 1;
      justify-content: end;
      .search-container {
        width: 50%;
      }
    }
  }

  @media (min-width: vars.$breakpoint-md) and (max-width: vars.$breakpoint-xl) {
    &.ee-toolbar-with-filter {
      padding-bottom: var(--space-8);
    }
    .competition-filter {
      top: var(--space-8);
      left: 0;
      position: absolute;
      padding-left: var(--space-6);
    }
  }
}
