@use "./../abstract/sass-vars" as vars;
.page-content {
  display: grid !important;
  grid-template-columns: 2fr 1fr;
  padding-bottom: var(--space-4);
  @media (max-width: vars.$breakpoint-lg) {
    display: flex;
    flex-direction: column !important;
    grid-template-columns: 1fr;
  }
}
.match-page-content {
  display: grid !important;
  grid-template-columns: 2fr 1.5fr;
  padding-bottom: var(--space-4);
  @media (max-width: vars.$breakpoint-lg) {
    display: flex !important;
    flex-direction: column-reverse !important;
    grid-template-columns: 1fr;
    .summary-news {
      display: none !important;
    }
  }
}

//team profile
.team-info {
  @media (max-width: vars.$breakpoint-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}

//Match card
.match-card {
  position: relative;
  .align-center {
    align-items: center;
  }
  .status-badge {
    position: absolute;
    top: 50%;
    left: calc(var(--space-md) * -1);
    transform: translateY(-50%);
  }
  .match-card-grid {
    grid-template-columns: 1fr 13rem !important;
  }
  .live-left-section {
    grid-template-columns: 1fr auto 1fr;
  }
  .left-section {
    grid-template-columns: 1fr var(--space-11) 1fr;
  }
  .match-detail-mobile {
    display: none !important;
  }
  @media (max-width: vars.$breakpoint-sm) {
    .match-card-grid {
      grid-template-columns: 1fr !important;
    }
    .match-home-team {
      flex-direction: column-reverse !important;
    }
    .match-away-team {
      flex-direction: column !important;
    }
    .match-detail-desktop {
      display: none !important;
    }
    .match-detail-mobile {
      display: flex !important;
    }
  }
  @media (max-width: vars.$breakpoint-md) {
    .status-badge {
      left: calc(var(--space-sm-mobile) * -1);
    }
  }
  @media (min-width: vars.$breakpoint-sm) and (max-width: vars.$breakpoint-lg) {
    .match-card-grid {
      grid-template-columns: 1fr 10rem !important;
    }
  }
}

.match-home-team {
  @media (max-width: vars.$breakpoint-sm) {
    flex-direction: column-reverse !important;
  }
}
.match-away-team {
  @media (max-width: vars.$breakpoint-sm) {
    flex-direction: column !important;
  }
}

//Associated league card component
.associated-league-card {
  flex: 1;
  max-width: var(--size-10);
  min-width: var(--size-10);
  text-align: center;
  .associated-image {
    width: var(--size-8) !important;
    height: var(--size-8) !important;
    background-color: rgba(var(--accent-600));
    color: rgba(var(--white)) !important;
  }
  @media (max-width: vars.$breakpoint-md) {
    max-width: var(--size-8) !important;
    min-width: var(--size-8) !important;
    .associated-image {
      width: var(--size-6) !important;
      height: var(--size-6) !important;
    }
  }
}

//Table standing

.standing-table {
  overflow: hidden;
  table {
    padding: 1rem;
    width: 100%;
    border-collapse: collapse;
    th {
      height: var(--size-6);
      border-bottom: 1px solid rgb(var(--border-default));
    }
    td,
    th {
      text-align: center;
      padding: var(--space-2xs);
      &:first-child {
        padding-left: var(--space-xs);
      }
      &:last-child {
        padding-right: var(--space-xs);
      }
    }
    @media (max-width: vars.$breakpoint-sm) {
      padding: 0.75rem;
      td,
      th {
        padding: var(--space-2xs-mobile);
        &:first-child {
          padding-left: var(--space-xs-mobile);
        }
        &:last-child {
          padding-right: var(--space-xs-mobile);
        }
      }
    }
  }
  .table-row {
    &.active-row {
      @each $color in vars.$colorOptions {
        &.color-#{$color} {
          background-color: rgb(var(--bg-translucent-#{$color}));
          position: relative;
          &:after {
            content: "";
            width: var(--size-0p5);
            height: 100%;
            background-color: rgb(var(--bg-solid-#{$color}));
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
    .data-cell {
      width: 8%;
    }
    .row-left-section {
      text-align: left;
      .index {
        width: var(--size-md);
      }
    }
  }

  .table-abbreviation {
    display: none !important;
  }

  @media (max-width: vars.$breakpoint-xs) {
    .table-form {
      display: none !important;
    }
    .table-point {
      padding-right: var(--space-xs);
    }
    .table-abbreviation {
      display: flex !important;
    }
    .table-name {
      display: none !important;
    }
  }

  @media (min-width: vars.$breakpoint-xs) and (max-width: vars.$breakpoint-md) {
    .table-abbreviation {
      display: flex !important;
    }
    .table-name {
      display: none !important;
    }
  }
}

//team header
.team-header {
  @media (max-width: vars.$breakpoint-sm) {
    flex-direction: column !important;
    align-items: start !important;
  }
}

// .team-sub-title {
//   @media (max-width: vars.$breakpoint-md) {
//     flex-direction: column !important;
//     align-items: start !important;
//     justify-content: start;
//   }
// }

// .team-sub-title-season {
//   @media (max-width: vars.$breakpoint-md) {
//     border: none !important;
//   }
// }

//Associated players
.associated-player {
  @media (max-width: vars.$breakpoint-sm) {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}
