@use "./../abstract/sass-vars" as vars;
//Player profile
.player-info {
  .player-box {
    position: relative;
    .player-grid-box {
      align-items: center;
      position: absolute;
      top: 0;
      right: 26%;
      left: 5%;
      bottom: 0;
      h6 {
        width: var(--size-xl);
        background-color: rgb(var(--bg-solid-primary));
        color: rgb(var(--white));
        border-radius: var(--radius-default);
        @media (max-width: vars.$breakpoint-sm) {
          font-size: var(--label-sm-font-size-mobile);
          width: var(--size-md);
        }
        @media (min-width: vars.$breakpoint-sm) and (max-width: vars.$breakpoint-lg) {
          font-size: var(--label-md-font-size-mobile);
          width: var(--size-lg);
        }
      }
    }
  }
  
  @media (max-width: vars.$breakpoint-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
//Player attribute
.player-attribute {
  .attribute-chart {
    height: auto !important;
  }
  .attribute-compare {
    position: relative;
    .ee-input {
      width: 100%;
      padding-right: var(--space-lg);
    }
    .swap-icon {
      position: absolute;
      right: var(--space-2xs);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
//player training
.training-stadium {
  min-width: var(--size-16);
  max-width: var(--size-16);
}

//player-details
.player-header {
  @media (max-width: vars.$breakpoint-sm) {
    flex-direction: column !important;
    align-items: start !important;
  }
}

.player-sub-title {
  @media (max-width: vars.$breakpoint-md) {
    flex-direction: column !important;
    align-items: start !important;
    justify-content: start;
  }
}

.player-header-info {
  @media (max-width: vars.$breakpoint-sm) {
    display: none !important;
    padding: none;
  }
  @media (min-width: vars.$breakpoint-sm) and (max-width: vars.$breakpoint-lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}

.player-position {
  @media (max-width: vars.$breakpoint-sm) {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}
