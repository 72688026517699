@use "./../abstract/sass-vars" as vars;
$textColorOptions: primary, accent, info, success, warning, danger, dark, default, muted, currentColor;
$textTypeSizeOptions: lg, md, sm;
$fontWeightOptions: thin, light, normal, md, semibold, bold, extrabold;
$textTypeOptions: body, label, title;
$textAlignOptions: left, center, right, justify, start, end;
$textTransformOptions: uppercase, lowercase, capitalize, none;
$textDecorationOption: underline, overline, line-through, none;
$listStyleOptions: none, disc, circle, decimal;

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--heading-font-family-name);
  font-style: var(--heading-font-family-style);
  font-weight: var(--heading-font-weight);
}

.h1 {
  font-size: var(--heading-1-font-size);
  line-height: var(--heading-1-line-height);
  @media (max-width: vars.$breakpoint-lg) {
    font-size: var(--heading-1-font-size-mobile);
    line-height: var(--heading-1-line-height-mobile);
  }
}
.h2 {
  font-size: var(--heading-2-font-size);
  line-height: var(--heading-2-line-height);
  @media (max-width: vars.$breakpoint-lg) {
    font-size: var(--heading-2-font-size-mobile);
    line-height: var(--heading-2-line-height-mobile);
  }
}
.h3 {
  font-size: var(--heading-3-font-size);
  line-height: var(--heading-3-line-height);
  @media (max-width: vars.$breakpoint-lg) {
    font-size: var(--heading-3-font-size-mobile);
    line-height: var(--heading-3-line-height-mobile);
  }
}
.h4 {
  font-size: var(--heading-4-font-size);
  line-height: var(--heading-4-line-height);
  @media (max-width: vars.$breakpoint-lg) {
    font-size: var(--heading-4-font-size-mobile);
    line-height: var(--heading-4-line-height-mobile);
  }
}
.h5 {
  font-size: var(--heading-5-font-size);
  line-height: var(--heading-5-line-height);
  @media (max-width: vars.$breakpoint-lg) {
    font-size: var(--heading-5-font-size-mobile);
    line-height: var(--heading-5-line-height-mobile);
  }
}
.h6 {
  font-size: var(--heading-6-font-size);
  line-height: var(--heading-6-line-height);
  @media (max-width: vars.$breakpoint-lg) {
    font-size: var(--heading-6-font-size-mobile);
    line-height: var(--heading-6-line-height-mobile);
  }
}

/* text styles & sizes */
@each $textType in $textTypeOptions {
  @each $textSize in $textTypeSizeOptions {
    .#{$textType}-#{$textSize} {
      font-family: var(--#{$textType}-font-family-name);
      font-style: var(--#{$textType}-font-family-style);
      font-weight: var(--#{$textType}-font-weight);
      font-size: var(--#{$textType}-#{$textSize}-font-size);
      @media (max-width: vars.$breakpoint-lg) {
        font-size: var(--#{$textType}-#{$textSize}-font-size-mobile);
        line-height: var(--#{$textType}-#{$textSize}-line-height-mobile);
      }
    }
  }
}

/* line clamp */
.line-clamp-none {
  overflow: visible;
  display: block;
  -webkit-box-orient: horizontal;
  -webkit-line-clamp: none;
}

.white-space {
  white-space: break-spaces;
}

@for $i from 1 through 6 {
  .line-clamp-#{$i} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: #{$i};
  }
}

/* list style */
@each $style in $listStyleOptions {
  .list-#{$style} {
    list-style: #{$style};
  }
}

/* text align */
@each $align in $textAlignOptions {
  .text-#{$align} {
    text-align: #{$align};
  }
}

/* text transfrom */
@each $transfromOption in $textTransformOptions {
  .text-#{$transfromOption} {
    text-transform: #{$transfromOption};
  }
}

/* text transfrom */
@each $decorationOption in $textDecorationOption {
  .text-#{$decorationOption} {
    text-decoration: #{$decorationOption};
  }
}

/* text color */
@each $color in $textColorOptions {
  .text-#{$color} {
    color: rgba(var(--text-#{$color}));
  }
}

/* font weight */
@each $weight in $fontWeightOptions {
  .font-#{$weight} {
    font-weight: var(--weight-#{$weight});
  }
}

/* font style */
.font-italic {
  font-style: italic;
}

.font-normal {
  font-style: normal;
}

.font-ordinal {
  font-variant-numeric: ordinal;
}

.font-slashed-zero {
  font-variant-numeric: slashed-zero;
}
