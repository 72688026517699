.ee-triple-toggle-switch-container {
  display: inline-block;
  vertical-align: middle;
  width: var(--size-18);
  height: var(--size-3);
  border: var(--size-px) solid rgba(var(--border-200));
  border-radius: var(--radius-rounded);
  background-color: var(--surface-200);
  position: relative;
  box-sizing: content-box;
  input[type="radio"] {
    display: none;
  }

  &.without-center {
    width: var(--size-15);
  }

  label {
    color: rgba(var(--border-200));
    z-index: 10;
    cursor: pointer;
    user-select: none;
    font-weight: 600;
    bottom: var(--size-px);
  }

  .selected-font {
    color: rgba(var(--white)) !important;
  }

  .switch {
    height: var(--size-3);
    width: var(--size-6);
    background-color: rgba(var(--primary-600));
    border-radius: var(--radius-rounded);
    position: absolute;
    transition: ease-in;
    &.switch-without-center {
      width: var(--size-3);
    }
  }

  /*---------------- LEFT ------------------- */
  .switch.left-to-center {
    animation-name: leftToCenter;
    animation-duration: 0.5s;
  }

  .switch.left-to-right {
    animation-name: leftToRight;
    animation-duration: 0.5s;
  }

  .switch.left-position {
    background-color: rgba(var(--success-600));
  }

  .left-label {
    position: absolute;
  }

  .left + label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--size-3);
    width: var(--size-6);
    border-radius: var(--radius-rounded);
  }

  .left:checked + label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--size-3);
    width: var(--size-6);
    border-radius: var(--radius-rounded);
  }

  @keyframes leftToCenter {
    from {
      left: 0;
    }
    to {
      left: var(--size-6);
    }
  }

  @keyframes leftToRight {
    from {
      left: 0;
    }
    to {
      right: 0;
    }
  }

  /* -------------------- center ------------------ */
  .switch.center-to-left {
    animation-name: centerToLeft;
    animation-duration: 0.5s;
  }

  .switch.center-to-right {
    animation-name: centerToRight;
    animation-duration: 0.5s;
  }

  .switch.center-position {
    left: var(--size-6);
  }

  .center-label {
    position: absolute;
    left: var(--size-6);
    &.without-center {
      width: var(--size-3);
    }
    .rounded-divider {
      background-color: rgba(var(--border-200)) !important;
    }
  }

  .center + label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--size-3);
    width: var(--size-6);
    border-radius: var(--radius-rounded);
    &.without-center {
      width: var(--size-3);
    }
  }

  .center:checked + label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--size-3);
    width: var(--size-6);
    border-radius: var(--radius-rounded);
    &.without-center {
      width: var(--size-3);
    }
  }

  @keyframes centerToLeft {
    from {
      left: var(--size-3);
    }
    to {
      left: 0;
    }
  }

  @keyframes centerToRight {
    from {
      left: var(--size-6);
    }
    to {
      right: 0;
    }
  }

  /* ------------------- RIGHT ------------------- */
  .switch.right-to-left {
    animation-name: rightToLeft;
    animation-duration: 0.5s;
    &.without-center {
      animation-name: rightToLeftWithoutCenter;
    }
  }

  .switch.right-to-center {
    animation-name: rightToCenter;
    animation-duration: 0.5s;
    &.without-center {
      animation-name: rightToCenterWithoutCenter;
    }
  }

  .switch.right-position {
    left: var(--size-12);
    background-color: rgba(var(--danger-600));
    &.without-center {
      left: var(--size-9);
    }
  }

  .right-label {
    position: absolute;
    right: 1px;
  }

  .right + label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--size-3);
    width: var(--size-6);
    border-radius: var(--radius-rounded);
  }

  .right:checked + label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--size-3);
    width: var(--size-6);
    border-radius: var(--radius-rounded);
  }

  @keyframes rightToLeft {
    from {
      right: 0;
    }
    to {
      left: 0;
    }
  }

  @keyframes rightToCenter {
    from {
      right: 0;
    }
    to {
      right: var(--size-6);
    }
  }

  @keyframes rightToLeftWithoutCenter {
    from {
      left: var(--size-9);
    }
    to {
      left: 0;
    }
  }

  @keyframes rightToCenterWithoutCenter {
    from {
      left: var(--size-12);
    }
    to {
      right: var(--size-6);
    }
  }

  .disable-button {
    cursor: not-allowed;
  }
}
