@use "./../abstract/sass-vars" as vars;
$avatarSizeOptions: xs, sm, md, lg, xl, 2xl, 3xl, 4xl, 5xl, 6xl;

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: var(--weight-semibold);
  background-color: rgba(var(--surface-100));
  border: 1px solid var(--transparent);
  img {
    object-fit: cover;
    border-radius: var(--radius-rounded);
    height: 100%;
    width: 100%;
  }

  .badge {
    position: absolute;
  }

  @each $size in $avatarSizeOptions {
    &.size-#{$size} {
      height: var(--avatar-#{$size});
      width: var(--avatar-#{$size});
      min-width: var(--avatar-#{$size});
      font-size: var(--text-#{$size});

      @media (max-width: vars.$breakpoint-lg) {
        height: var(--avatar-#{$size}-mobile);
        width: var(--avatar-#{$size}-mobile);
        min-width: var(--avatar-#{$size}-mobile);
        font-size: var(--text-#{$size}-mobile);
      }

      .svg-icon {
        height: 50%;
        width: 50%;
      }
    }
  }

  &.active {
    &:after {
      display: block;
      content: "";
      position: absolute;
      inset: 0;
      margin: calc(var(--size-2) * -1);
      border: var(--size-1) solid var(--avatar-active-ring-color);
      border-radius: var(--radius-rounded);
    }
  }

  &-btn {
    background-color: var(--button-bg-default);
    border: 1px solid rgba(var(--border-default));
    color: currentColor;
    cursor: pointer;

    &:hover {
      background-color: var(--button-bg-default-hover);
    }

    &:active,
    &.active {
      background-color: var(--button-bg-default-active);
    }
  }
}
