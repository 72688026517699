@use "./../abstract/sass-vars" as vars;

[data-theme="light"] {
  /* background colors */
  --bg-solid-primary: var(--primary-500);
  --bg-solid-primary-dark: var(--primary-900);
  --bg-solid-accent: var(--accent-500);
  --bg-solid-accent-dark: var(--accent-900);
  --bg-solid-info: var(--info-500);
  --bg-solid-success: var(--success-500);
  --bg-solid-warning: var(--warning-500);
  --bg-solid-danger: var(--danger-500);
  --bg-solid-dark: var(--gray-500);
  --bg-translucent-primary: var(--primary-500) / 12%;
  --bg-translucent-accent: var(--accent-500) / 12%;
  --bg-translucent-info: var(--info-500) / 12%;
  --bg-translucent-success: var(--success-500) / 12%;
  --bg-translucent-warning: var(--warning-500) / 12%;
  --bg-translucent-danger: var(--danger-500) / 12%;
  --bg-translucent-dark: var(--gary-500) / 12%;

  /* border colors */
  --border-100: var(--gray-50);
  --border-200: var(--gray-100);
  --border-300: var(--gray-200);
  --border-default: var(--border-100);
  --border-dark: var(--border-200);
  --border-primary: var(--primary-500);
  --border-accent: var(--accent-500);
  --border-info: var(--info-500);
  --border-success: var(--success-500);
  --border-warning: var(--warning-500);
  --border-danger: var(--danger-500);

  /* foreground colors */
  --fg-solid-primary: var(--white);
  --fg-solid-accent: var(--white);
  --fg-solid-dark: var(--white);
  --fg-solid-info: var(--white);
  --fg-solid-success: var(--white);
  --fg-solid-warning: var(--white);
  --fg-solid-danger: var(--white);

  /* surface colors */
  --surface-100: rgba(255 255 255);
  --surface-200: rgba(251 250 249);
  --surface-300: rgba(246 244 243);
  --surface-base: var(--surface-200);
  --surface-invert: var(--gray-800);

  /* text colors */
  --text-100: var(--gray-900);
  --text-200: var(--gray-700);
  --text-300: var(--gray-400);
  --text-dark: var(--text-100);
  --text-default: var(--text-200);
  --text-muted: var(--text-300);
  --text-primary: var(--primary-700);
  --text-accent: var(--accent-700);
  --text-info: var(--info-700);
  --text-success: var(--success-700);
  --text-warning: var(--warning-700);
  --text-danger: var(--danger-700);
  --text-invert: var(--gray-50);

  /* colors */
  @each $color in vars.$colorOptions {
    /* buttons */
    // filled
    --button-bg-filled-#{$color}: rgba(var(--#{$color}-500));
    --button-bg-filled-#{$color}-hover: rgba(var(--#{$color}-600));
    --button-bg-filled-#{$color}-active: rgba(var(--#{$color}-700));

    // outline
    --button-bg-outline-#{$color}-hover: rgba(var(--#{$color}-100) / 40%);
    --button-bg-outline-#{$color}-active: rgba(var(--#{$color}-100) / 72%);
    --button-fg-outline-#{$color}: rgba(var(--#{$color}-700));
    --button-fg-outline-gray: rgba(var(--black));

    // light
    --button-bg-light-#{$color}: rgba(var(--#{$color}-100) / 32%);
    --button-bg-light-#{$color}-hover: rgba(var(--#{$color}-100) / 64%);
    --button-bg-light-#{$color}-active: rgba(var(--#{$color}-100) / 88%);
    --button-fg-light-#{$color}: rgba(var(--#{$color}-700));
    --button-fg-light-gray: rgba(var(--black));

    // subtle
    --button-bg-subtle-#{$color}-hover: rgba(var(--#{$color}-100) / 40%);
    --button-bg-subtle-#{$color}-active: rgba(var(--#{$color}-100) / 80%);
    --button-fg-subtle-#{$color}: rgba(var(--#{$color}-700));
    --button-fg-subtle-gray: rgba(var(--black));

    /* bg-fg-options */

    /* filled */
    --bg-filled-#{$color}: rgba(var(--#{$color}-500));

    /* outline */
    --bg-outline-#{$color}: var(--transparent);
    --fg-outline-#{$color}: rgba(var(--#{$color}-500));

    /* light */
    --bg-light-#{$color}: rgba(var(--#{$color}-100) / 32%);
    --fg-light-#{$color}: rgba(var(--#{$color}-500));

    /* subtle */
    --fg-subtle-#{$color}: rgba(var(--#{$color}-500));
  }
  --fg-filled: rgba(var(--white));
  --bg-default: rgba(var(--white));
  --fg-default: currentColor;

  /* date-picker*/
  --close-fg: rgba(var(--black));

  /* avatar */
  --avatar-active-ring-color: rgba(var(--primary-500));

  /* skeleton*/
  --skeleton-shimmer-color: rgba(255, 255, 255, 0.5);

  /* tournament timeline*/
  --timeline-bg-color: rgba(var(--info-200));
}
