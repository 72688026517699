@use "./../abstract/sass-vars" as vars;
$buttonSizeOptions: xs, sm, md, lg, xl;
$buttonColorOptions: primary, accent, info, success, warning, danger, gray;

:root {
  --button-fg-filled: rgba(var(--white));
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin button-base() {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  font-family: inherit;
  color: currentColor;
  text-align: center;
  transition: background-color 0.15s ease, color 0.15s ease, box-shadow 0.15s ease, border-color 0.15s ease;

  border-width: var(--size-px);
  border-style: solid;
  border-color: var(--transparent);

  font-weight: var(--weight-semibold);
  border-radius: var(--radius-default);
  background: var(--transparent);
  text-wrap: nowrap;

  &.btn-icon {
    gap: unset;
    padding: unset;
  }

  &:focus {
    &:focus-visible {
      outline-offset: 0.125rem;
      outline: 0.125rem solid rgba(var(--text-default));
    }
  }

  &:disabled {
    background-color: var(--disabled-bg);
    color: var(--disabled-fg);
    cursor: initial;
  }
}

@mixin button-sizes() {
  @each $size in $buttonSizeOptions {
    &.size-#{$size} {
      height: var(--button-#{$size}-size);
      font-size: var(--button-#{$size}-text);
      padding: var(--button-#{$size}-padding);
      gap: var(--button-#{$size}-gap);

      @media (max-width: vars.$breakpoint-lg) {
        height: var(--button-#{$size}-size-mobile);
        font-size: var(--button-#{$size}-text-mobile);
        padding: var(--button-#{$size}-padding-mobile);
        gap: var(--button-#{$size}-gap-mobile);
      }

      .svg-icon {
        height: var(--button-#{$size}-icon-size);
        width: var(--button-#{$size}-icon-size);

        @media (max-width: vars.$breakpoint-lg) {
          height: var(--button-#{$size}-icon-size-mobile);
          width: var(--button-#{$size}-icon-size-mobile);
        }
      }

      &.btn-icon {
        width: var(--button-#{$size}-size);
        min-width: var(--button-#{$size}-size);
        height: var(--button-#{$size}-size);
        min-height: var(--button-#{$size}-size);
        padding: unset;
        @media (max-width: vars.$breakpoint-lg) {
          width: var(--button-#{$size}-size-mobile);
          min-width: var(--button-#{$size}-size-mobile);
          height: var(--button-#{$size}-size-mobile);
          min-height: var(--button-#{$size}-size-mobile);
        }
      }
    }
  }
}

@mixin button-filled() {
  &.btn-filled {
    color: var(--button-fg-filled);

    @each $color in $buttonColorOptions {
      &.color-#{$color} {
        &:not(:disabled) {
          background-color: var(--button-bg-filled-#{$color});

          &:hover {
            background-color: var(--button-bg-filled-#{$color}-hover);
          }

          &:active,
          &.active {
            background-color: var(--button-bg-filled-#{$color}-active);
          }
        }
      }
      .btn-loading-spinner-#{$color} {
        border: 4px solid var(--button-fg-filled);
        border-left-color: var(--button-bg-filled-#{$color});
        border-radius: 50%;
        width: 1.25rem;
        height: 1.25rem;
        animation: spin 1s linear infinite;
      }
    }
  }
}

@mixin button-outline() {
  &.btn-outline {
    background-color: var(--surface-100);
    border-color: currentColor;

    @each $color in $buttonColorOptions {
      &.color-#{$color} {
        &:not(:disabled) {
          color: var(--button-fg-outline-#{$color});

          &:hover {
            background-color: var(--button-bg-outline-#{$color}-hover);
          }

          &:active,
          &.active {
            background-color: var(--button-bg-outline-#{$color}-active);
          }
        }
      }
      .btn-loading-spinner-#{$color} {
        border: 4px solid var(--button-fg-outline-#{$color});
        border-left-color: var(--surface-100);
        border-radius: 50%;
        width: 1.25rem;
        height: 1.25rem;
        animation: spin 1s linear infinite;
      }
    }
  }
  &.border-light {
    border-color: rgb(var(--border-200));
  }
}

@mixin button-light() {
  &.btn-light {
    @each $color in $buttonColorOptions {
      &.color-#{$color} {
        &:not(:disabled) {
          background-color: var(--button-bg-light-#{$color});
          color: var(--button-fg-light-#{$color});

          &:hover {
            background-color: var(--button-bg-light-#{$color}-hover);
          }

          &:active,
          &.active {
            background-color: var(--button-bg-light-#{$color}-active);
          }
        }
        .btn-loading-spinner-#{$color} {
          border: 4px solid var(--button-fg-light-#{$color});
          border-left-color: var(--button-bg-light-#{$color});
          border-radius: 50%;
          width: 1.25rem;
          height: 1.25rem;
          animation: spin 1s linear infinite;
        }
      }
    }
  }
}

@mixin button-subtle() {
  &.btn-subtle {
    background-color: var(--transparent);

    @each $color in $buttonColorOptions {
      &.color-#{$color} {
        &:not(:disabled) {
          color: var(--button-fg-subtle-#{$color});

          &:hover {
            background-color: var(--button-bg-subtle-#{$color}-hover);
          }

          &:active,
          &.active {
            background-color: var(--button-bg-subtle-#{$color}-active);
          }
        }
      }
      .btn-loading-spinner-#{$color} {
        border: 4px solid var(--button-fg-subtle-#{$color});
        border-left-color: var(--transparent);
        border-radius: 50%;
        width: 1.25rem;
        height: 1.25rem;
        animation: spin 1s linear infinite;
      }
    }
  }
}

.btn {
  @include button-base();
  @include button-sizes();
  @include button-filled();
  @include button-outline();
  @include button-light();
  @include button-subtle();
}
