@use "./../abstract/sass-vars" as vars;

.surface-100 {
  background-color: rgba(var(--surface-100));
}

.surface-200 {
  background-color: rgba(var(--surface-200));
}

.surface-300 {
  background-color: rgba(var(--surface-300));
}

.bg-filled,
.bg-light,
.bg-subtle,
.bg-outline {
  border-width: var(--size-px);
}

@each $color in vars.$colorOptions {
  .bg-filled {
    &.color-#{$color} {
      background-color: var(--bg-filled-#{$color});
      color: var(--fg-filled);
      border-color: var(--bg-filled-#{$color});
    }
  }

  .bg-outline {
    &.color-#{$color} {
      background-color: var(--bg-outline-#{$color});
      color: var(--fg-outline-#{$color});
      border-color: var(--fg-outline-#{$color});
    }
  }

  .bg-light {
    &.color-#{$color} {
      background-color: var(--bg-light-#{$color});
      color: var(--fg-light-#{$color});
      border-color: var(--transparent);
    }
  }

  .bg-subtle {
    &.color-#{$color} {
      background-color: var(--transparent);
      color: var(--fg-light-#{$color});
      border-color: var(--transparent);
    }
  }

  .fill-#{$color} {
    fill: var(--bg-filled-#{$color});
  }
}
