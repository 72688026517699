@use "./../abstract/sass-vars" as vars;
$avatarSizeOptions: xs, md, lg, xl, 2xl;

.skeleton {
  background: var(--surface-300);
  border-radius: var(--radius-sm);
  position: relative;
  overflow: hidden;
  &.label {
    width: var((--size-12));
    height: var(--size-2);
    @media (max-width: vars.$breakpoint-sm) {
      width: var((--size-6));
    }
  }
  &.text {
    width: 100%;
    height: var(--size-2);
    margin-bottom: var(--space-0p5);
  }
  &.title {
    width: var((--size-10));
    height: var(--size-3);
    margin-bottom: var(--space-1);
    @media (max-width: vars.$breakpoint-sm) {
      width: var((--size-8));
    }
  }
  @each $size in $avatarSizeOptions {
    &.avatar-#{$size} {
      width: var(--avatar-#{$size});
      height: var(--avatar-#{$size});
      border-radius: var(--radius-rounded);
      @media (max-width: vars.$breakpoint-md) {
        width: var(--avatar-#{$size}-mobile);
        height: var(--avatar-#{$size}-mobile);
      }
    }
  }
  &.button {
    width: 100%;
    height: var(--size-5);
  }
  &.pill {
    width: var(--size-8);
    height: var(--pill-height);
    border-radius: var(--radius-rounded);
    @media (max-width: vars.$breakpoint-sm) {
      width: var((--size-6));
    }
  }
  &.badge {
    width: var(--size-lg);
    height: var(--size-lg);
    @media (max-width: vars.$breakpoint-sm) {
      width: var(--size-sm);
      height: var(--size-sm);
    }
  }
  &.thumbnail {
    width: 100%;
    height: 100%;
  }
}

.shimmer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  animation: loading 1s infinite;
}

.shimmer {
  width: 60%;
  height: 100%;
  background: var(--skeleton-shimmer-color);
}

@keyframes loading {
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(150%);
  }
}
