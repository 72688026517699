@use "./../abstract/sass-vars" as vars;
$stateColors: info, success, warning, danger;

.ee-input-group {
  display: flex;
  flex-direction: column;

  .ee-input-label {
    margin-bottom: var(--space-4);
  }

  .ee-input-hint {
    margin-top: var(--space-3);
  }
}

.ee-input-label {
  display: flex;
  font-size: var(--label-medium-font-size);
  line-height: var(--label-medium-line-height);
  letter-spacing: var(--label-medium-letter-spacing);
  font-weight: var(--label-font-weight);

  &.ee-required {
    &:after {
      content: "*";
      display: block;
      color: rgba(var(--text-danger));
      margin-left: var(--space-2);
      font-size: var(--text-md);
    }
  }
}

.ee-input {
  border-width: var(--size-px);
  border-style: solid;
  border-radius: var(--radius-default);
  border-color: rgba(var(--border-200));
  background-color: var(--surface-100);
  outline: unset;
  font-family: inherit;

  height: var(--size-5);
  font-size: var(--text-sm);
  color: rgba(var(--text-100));
  padding: 0 var(--space-2);

  transition: border-color 0.15s ease, box-shadow 0.15s ease;

  &[data-error="true"],
  &[data-warning="true"],
  &[data-info="true"],
  &[data-success="true"] {
    border-color: currentColor;

    // &::placeholder {
    //   opacity: 1;
    // }
  }

  &[data-error="true"] {
    border-color: rgba(var(--text-danger));
  }
  &[data-warning="true"] {
    border-color: rgba(var(--text-warning));
  }
  &[data-info="true"] {
    border-color: rgba(var(--text-info));
  }
  &[data-success="true"] {
    border-color: rgba(var(--text-success));
  }

  &-filled {
    background-color: var(--input-filled-bg);
    border-color: var(--transparent);
  }

  &::placeholder {
    color: currentColor;
    opacity: var(--placeholder-opacity);
  }

  &:focus {
    &:focus-visible {
      border-color: var(--fg-outline-accent);
      box-shadow: inset 0 0 0 var(--size-px) var(--fg-outline-accent);
    }
  }

  &:disabled {
    opacity: var(--disabled-opacity);
    background-color: var(--disabled-bg);
    color: var(--disabled-fg);
  }

  &.drop-down-small {
    height: var(--size-3);
    font-size: var(--text-xs);
    padding: 0 var(--space-1);
  }

  @media (max-width: vars.$breakpoint-md) {
    height: var(--size-4);
  }
  
  @media (max-width: vars.$breakpoint-lg) {
    font-size: var(--text-sm-mobile);
  }
}

.ee-input-hint {
  font-size: var(--label-small-font-size);
  line-height: var(--label-small-line-height);
  letter-spacing: var(--label-small-letter-spacing);

  @each $color in $stateColors {
    &.ee-hint-#{$color} {
      color: rgba(var(--text-#{$color}));
    }
  }
}

.ee-input-textarea {
  height: var(--size-16);
  padding-top: 1rem;
  padding-bottom: 1rem;
  resize: none;
}

/* search */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.ee-search-box {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  .ee-input {
    padding-right: var(--space-5);
    width: 100%;
  }

  .search-actions {
    position: absolute;
    right: var(--space-1);
    top: 50%;
    transform: translateY(-50%);
  }
}

/* Dropdown */
.ee-dropdown {
  position: relative;
  ::-webkit-scrollbar {
    width: var(--size-0p5);
  }
  ::-webkit-scrollbar-track {
    background: var(--surface-200);
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(var(--border-200));
    border-radius: var(--radius-rounded);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--border-300));
  }
}

.ee-dropdown,
.ee-dropdown-btn {
  cursor: pointer;
  min-width: var(--size-18);
  @media (max-width: vars.$breakpoint-sm) {
    min-width: var(--size-4);
  }
}
.drop-down-small {
  min-width: var(--size-10) !important;
  max-width: var(--size-14) !important;
}
.ee-dropdown-content {
  max-height: var(--size-18);
  overflow-y: auto;
  position: absolute;
  left: 0;
  width: 100%;
  border-width: var(--size-px);
  border-style: solid;
  border-radius: var(--radius-default);
  border-color: rgba(var(--border-200));
  background-color: var(--surface-100);
  z-index: 50;

  &.drop-down {
    top: 100%;
    margin-top: var(--space-1);
  }

  &.drop-up {
    bottom: 100%;
    margin-bottom: var(--space-1);
  }

  &.height-md {
    max-height: var(--size-18);
  }
  &.height-lg {
    max-height: 15rem;
  }
}

.ee-dropdown-content .item {
  background-color: var(--surface-100);
  padding: var(--space-1) var(--space-2);
  cursor: pointer;
  &.drop-down-small {
    padding: var(--space-0p5) var(--space-1);
  }
}
.ee-dropdown-content .item:hover {
  background-color: var(--button-bg-light-primary);
}

.ee-dropdown-content .item.selected {
  background-color: var(--button-bg-light-primary-active);
  border: 0.5px solid rgba(var(--border-200));
}

.ee-dropdown-search-container {
  position: sticky;
  top: 0;
  background-color: var(--surface-100);
  padding: var(--space-1);
  z-index: 10;
}

.ee-dropdown-search {
  width: 100%;
  padding: var(--space-1);
  border: 1px solid rgba(var(--border-200));
  border-radius: var(--radius-default);
}

//slider
.range-slider {
  position: relative;
  width: 15rem;
}

.range-slider_input {
  -webkit-appearance: none;
  appearance: none;
  height: var(--size-0p5);
  width: 100%;
  position: absolute;
  top: 50%;
  z-index: 3;
  transform: translateY(-50%);
  width: 100%;
  height: 4px;
  opacity: 0;
  margin: 0;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */

.range-slider_input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  background: (var(--surface-100));
  border: var(--size-px) solid rgba(var(--primary-500));
  border-radius: var(--radius-rounded);
  cursor: pointer;
}

.range-slider_input::-moz-range-thumb {
  width: 24px;
  height: 24px;
  background: rgba(var(--surface-100));
  border: var(--size-px) solid rgba(var(--primary-500));
  border-radius: var(--radius-rounded);
  cursor: pointer;
}

.range-slider_thumb {
  width: 24px;
  height: 24px;
  border: var(--size-px) solid rgba(var(--primary-500));
  border-radius: var(--radius-rounded);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: (var(--surface-100));
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.range-slider_line {
  width: 100%;
  height: var(--size-0p5);
  background-color: rgba(var(--border-200));
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  position: absolute;
  z-index: 1;
  border-radius: var(--radius-rounded);
}

.range-slider_line-fill {
  position: absolute;
  height: var(--size-0p5);
  width: 0;
  background-color: rgba(var(--primary-500));
  border-radius: var(--radius-rounded);
}
//checkbox switch

.checkbox-switch {
  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    width: var(--size-5);
    height: var(--size-3);
    display: block;
    border-width: var(--size-px);
    border-style: solid;
    border-radius: var(--radius-rounded);
    border-color: rgba(var(--border-200));
    background-color: var(--surface-100);
    position: relative;
  }

  label:after {
    content: "";
    position: absolute;
    top: var(--size-px);
    left: var(--size-px);
    width: 20px;
    height: 20px;
    background: rgba(var(--border-200));
    border-radius: var(--radius-rounded);
    transition: 0.1s;
  }

  input:checked + label {
    background: var(--button-bg-filled-primary);
  }

  input:checked + label:after {
    left: calc(100% - var(--size-px));
    transform: translateX(-100%);
  }

  label:active:after {
    width: var(--size-4);
  }
}

//checkbox
.ee-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  height: var(--size-4);
  width: var(--size-4);

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: var(--size-4);
    width: var(--size-4);
    background-color: var(--surface-200);
    border-width: var(--size-px);
    border-style: solid;
    border-radius: var(--radius-rounded);
    border-color: rgba(var(--border-200));

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  &:hover input ~ .checkmark {
    background-color: var(--surface-300);
  }

  input:checked ~ .checkmark {
    background-color: rgba(var(--success-500));
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 40%;
    top: 20%;
    width: var(--size-1);
    height: var(--size-2);
    border: solid white;
    border-width: 0 var(--size-0p5) var(--size-0p5) 0;
    transform: rotate(45deg);
  }

  &.small {
    height: var(--size-3);
    width: var(--size-3);
    .checkmark {
      height: var(--size-3);
      width: var(--size-3);
    }
    .checkmark:after {
      left: 30%;
      top: 10%;
    }
  }
}

.radio-option {
  .ee-radio {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    padding-left: var(--space-4);
    align-items: center;
    font-size: var(--text-sm);
    padding-top: var(--space-0p5);
    text-wrap: nowrap;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .radio-check {
      position: absolute;
      top: 0;
      left: 0;
      height: var(--size-3);
      width: var(--size-3);
      background-color: var(--surface-200);
      border: var(--size-px) solid rgba(var(--border-200));
      border-radius: var(--radius-rounded);
    }

    &:hover input ~ .radio-check {
      background-color: var(--surface-300);
    }

    input:checked ~ .radio-check {
      border-color: rgba(var(--border-success));
    }

    .radio-check:after {
      content: "";
      position: absolute;
      display: none;
    }

    input:checked ~ .radio-check:after {
      display: block;
    }

    .radio-check:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: var(--size-2);
      height: var(--size-2);
      border-radius: var(--radius-rounded);
      background: rgba(var(--border-success));
    }
  }
}

.time-picker-input {
  input {
    width: 60px;
    text-align: center;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
